import React from "react"
// import ContactForm from "../components/Contact/ContactForm"
import ContactImage from "../components/Contact/ContactImage"
import { Layout } from "../components/global"
import "../components/Contact/contact.css"
import { Helmet } from "react-helmet"

const contact = () => {
  return (
    <Layout lightNav={true}>
      <Helmet>
        <title>Keyla Technologies - Contact</title>
      </Helmet>
      <div className="contact-page-container">
        <div className="contact-page-item-container">
          <ContactImage />
        </div>
        {/* <div className="contact-page-item-container">
          <ContactForm />
        </div> */}
      </div>
    </Layout>
  )
}

export default contact
