import React from "react"
import "./contact.css"
import LocationIcon from "../../../static/assets/icons/location.svg"

function ContactImage() {
  return (
    <div className="contact-image-container">
      <div className="contact-text-container">
        <span className="contact-image-text-header">Lagos</span>
        <div className="contact-image-text-subtitle-container">
          <img src={LocationIcon} alt="" />
          <span className="contact-image-text-subtitle">
            2nd Floor, King’s deck building, Chevron
          </span>
        </div>
        <span className="contact-text">Info@keyla.ng</span>

        <span className="contact-text">+234 807 266 2804</span>
      </div>
    </div>
  )
}

export default ContactImage
